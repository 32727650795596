import { User as FirebaseUser } from "firebase/auth";
import { 
  UserTier, 
  TemplateTier, 
  UserTierValues,
  tierLevels,
  getFeaturesByTier,
  canAccessTier,
  type SubscriptionStatus as SharedSubscriptionStatus
} from "../../../shared/types";

export { UserTierValues };
export type { UserTier, TemplateTier, SharedSubscriptionStatus as SubscriptionStatus };

// Extend Firebase User type with our custom properties
declare module 'firebase/auth' {
  interface User {
    tier: UserTier;
    isAdmin: boolean;
    subscriptionStatus: SharedSubscriptionStatus;
  }
}

export interface UserRole {
  isAdmin: boolean;
  tier: UserTier;
}

export interface AuthUser extends Omit<FirebaseUser, 'providerData'> {
  isAdmin: boolean;
  tier: UserTier;
  subscriptionStatus: SharedSubscriptionStatus;
}

export interface AuthError {
  code: string;
  message: string;
}

export const ADMIN_EMAIL = 'agencympire@gmail.com';

export function getUserRole(email: string | null): UserRole {
  if (!email) {
    return { isAdmin: false, tier: UserTierValues.visitor };
  }

  if (email === ADMIN_EMAIL) {
    return { isAdmin: true, tier: UserTierValues.ultimate };
  }

  // All logged-in users get freemium access by default
  return { isAdmin: false, tier: UserTierValues.freemium };
}

export function canAccessTemplate(userTier: UserTier, templateTier: TemplateTier, subscriptionStatus: SharedSubscriptionStatus = 'none'): boolean {
  console.log('Access Check:', { userTier, templateTier, subscriptionStatus });
  
  // Ultimate tier always has full access
  if (userTier === UserTierValues.ultimate) {
    return true;
  }
  
  // Premium users with active subscription get access to all content except ultimate
  if (userTier === UserTierValues.premium) {
    // Check for active subscription
    if (isActiveSubscription(subscriptionStatus)) {
      return templateTier !== UserTierValues.ultimate;
    }
    // Premium users without active subscription get freemium access
    return templateTier === UserTierValues.freemium;
  }
  
  // Freemium users only get access to freemium content
  if (userTier === UserTierValues.freemium) {
    return templateTier === UserTierValues.freemium;
  }

  // Visitors get no access
  return false;
}

export function canPerformAction(
  action: 'view' | 'copy' | 'download' | 'share' | 'edit' | 'delete' | 'create',
  userRole: UserRole,
  templateTier?: TemplateTier
): boolean {
  // Admin-only actions
  if (['edit', 'delete', 'create'].includes(action)) {
    return userRole.isAdmin && userRole.tier === UserTierValues.ultimate;
  }

  // If no tier specified or freemium template, allow access for logged-in users
  if (!templateTier || templateTier === 'freemium') {
    return userRole.tier !== 'visitor';  // Any logged-in user can access
  }

  // For premium templates
  if (templateTier === 'premium') {
    return userRole.tier === 'premium' || userRole.tier === 'ultimate';
  }

  // For ultimate templates
  if (templateTier === 'ultimate') {
    return userRole.tier === 'ultimate';
  }

  // Default fallback - freemium users can view and share basic templates
  switch (action) {
    case 'view':
      return true;  // Everyone can view
    case 'copy':
    case 'download':
    case 'share':
      return userRole.tier !== 'visitor';  // Any logged-in user
    default:
      return false;
  }
}

export function getTierBadgeColor(tier: TemplateTier | UserTier): string {
  switch (tier) {
    case UserTierValues.ultimate:
      return 'bg-gradient-to-r from-amber-500 via-yellow-400 to-amber-500 text-black font-bold';
    case UserTierValues.premium:
      return 'bg-gradient-to-r from-violet-600 via-purple-500 to-violet-600 text-white';
    case UserTierValues.freemium:
      return 'bg-gradient-to-r from-cyan-500 via-blue-500 to-cyan-500 text-white';
    case UserTierValues.visitor:
    default:
      return 'bg-gradient-to-r from-slate-600 via-gray-500 to-slate-600 text-white';
  }
}

export function getTierLabel(tier: TemplateTier | UserTier): string {
  switch (tier) {
    case UserTierValues.ultimate:
      return 'ULTIMATE';
    case UserTierValues.premium:
      return 'PREMIUM';
    case UserTierValues.freemium:
      return 'FREE';
    case UserTierValues.visitor:
      return 'GUEST';
    default:
      return String(tier).toUpperCase();
  }
}

// Type guard for active subscription
export function isActiveSubscription(status: SharedSubscriptionStatus): boolean {
  return status === 'active' || status === 'trialing';
}

// Helper function to get subscription display status
export function getSubscriptionDisplayStatus(status: SharedSubscriptionStatus): string {
  switch (status) {
    case 'active':
      return 'Active';
    case 'trialing':
      return 'Trial';
    case 'canceled':
      return 'Canceled';
    case 'none':
      return 'None';
    default:
      return 'Unknown';
  }
}

export interface UserProfile {
  uid: string;
  email: string | null;
  tier: UserTier;
  subscriptionStatus: SharedSubscriptionStatus;
  subscriptionId?: string;
  createdAt: Date;
  updatedAt: Date;
}