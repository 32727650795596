import { FileIcon } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import { getAuthToken } from './firebase';

export function getFileExtension(filename: string): string {
  return filename.split('.').pop()?.toLowerCase() || '';
}

export function getFileIcon(filename: string): LucideIcon {
  // For now just return the generic file icon
  // You can extend this to return different icons based on file extension
  return FileIcon;
}

export interface FileData {
  name: string;
  url?: string;
  size?: number;
  type?: string;
}

export async function downloadFile(url: string) {
  const response = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${await getAuthToken()}`
    }
  });
  
  if (!response.ok) {
    throw new Error('Failed to download file');
  }

  return response.blob();
}
