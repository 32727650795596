import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Edit, Copy, Share2, ChevronLeft, ChevronDown, ChevronUp, Download } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { toast } from "@/components/ui/use-toast";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import ReactMarkdown from 'react-markdown';
import { useAuth } from "@/lib/auth";
import { 
  UserTierValues,
  TemplateTierValues,
  type TemplateContent,
  getFeaturesByTier,
  type UserTier,
  type TemplateTier,
  type Template
} from "@/types/shared";
import { cn } from "@/lib/utils";
import { createSubscriptionCheckout } from "@/lib/stripe";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import remarkGfm from 'remark-gfm';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import js from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import ts from 'react-syntax-highlighter/dist/cjs/languages/hljs/typescript';
import py from 'react-syntax-highlighter/dist/cjs/languages/hljs/python';
import jsonLang from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import sqlLang from 'react-syntax-highlighter/dist/cjs/languages/hljs/sql';
import bashLang from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash';
import yamlLang from 'react-syntax-highlighter/dist/cjs/languages/hljs/yaml';
import markdownLang from 'react-syntax-highlighter/dist/cjs/languages/hljs/markdown';
import { auth } from "@/lib/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import FilePreview from './FilePreview';
import { getFileIcon, getFileExtension } from '@/lib/files';
import mermaid from 'mermaid';

// Register languages
SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('typescript', ts);
SyntaxHighlighter.registerLanguage('python', py);
SyntaxHighlighter.registerLanguage('json', jsonLang);
SyntaxHighlighter.registerLanguage('sql', sqlLang);
SyntaxHighlighter.registerLanguage('bash', bashLang);
SyntaxHighlighter.registerLanguage('yaml', yamlLang);
SyntaxHighlighter.registerLanguage('markdown', markdownLang);

// Initialize mermaid
mermaid.initialize({
  startOnLoad: false,
  theme: 'dark',
  securityLevel: 'loose',
  fontFamily: 'ui-sans-serif, system-ui, sans-serif',
  fontSize: 14,
  darkMode: true,
  themeVariables: {
    primaryColor: '#7c3aed',
    primaryTextColor: '#fff',
    primaryBorderColor: '#7c3aed',
    lineColor: '#7c3aed',
    secondaryColor: '#4f46e5',
    tertiaryColor: '#3b82f6'
  }
});

interface ComponentsType {
  [key: string]: React.ComponentType<any>;
}

interface TableProps {
  children: React.ReactNode;
  className?: string;
}

interface CodeBlockProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ inline, className, children, ...props }) => {
  const [svg, setSvg] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const match = /language-(\w+)/.exec(className || "");
  const content = String(children).replace(/\n$/, '');

  const renderMermaidDiagram = useCallback(async (code: string) => {
    try {
      // Reset error state
      setError(null);
      
      // Generate unique ID for this diagram
      const id = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
      
      // Try to parse the diagram first
      await mermaid.parse(code);
      
      // If parsing succeeds, render the diagram
      const { svg } = await mermaid.render(id, code);
      return svg;
    } catch (error) {
      console.error('Mermaid rendering error:', error);
      setError(error instanceof Error ? error.message : 'Failed to render diagram');
      return '';
    }
  }, []);
  
  useEffect(() => {
    if (match && match[1] === 'mermaid') {
      renderMermaidDiagram(content).then(setSvg);
    }
  }, [content, match, renderMermaidDiagram]);

  if (match && match[1] === 'mermaid') {
    return (
      <div className="my-4 relative">
        {error ? (
          <div className="p-4 border border-red-500 rounded-lg bg-red-500/10 text-red-400">
            <p className="font-mono text-sm">Error rendering diagram: {error}</p>
            <pre className="mt-2 p-2 bg-black/50 rounded overflow-x-auto">
              {content}
            </pre>
          </div>
        ) : (
          <div 
            className="overflow-auto rounded-lg border border-purple-700 bg-purple-900/20 p-4"
            dangerouslySetInnerHTML={{ __html: svg }} 
          />
        )}
      </div>
    );
  }

  if (inline) {
    return <code className={className} {...props}>{children}</code>;
  }

  const language = match ? match[1] : '';

  return (
    <div className="not-prose my-4">
      <SyntaxHighlighter
        style={atomOneDark}
        language={language}
        PreTag="div"
        {...props}
      >
        {content}
      </SyntaxHighlighter>
    </div>
  );
};

const markdownComponents: ComponentsType = {
  code: CodeBlock as React.ComponentType<any>,
  pre: ({ children }: { children: React.ReactNode }) => <>{children}</>,
  p: ({ children, className }: { children: React.ReactNode; className?: string }) => {
    if (React.Children.toArray(children).some(child => 
      React.isValidElement(child) && child.type === CodeBlock
    )) {
      return <>{children}</>;
    }
    return <p className={className}>{children}</p>;
  },
  table: ({ children, className }: TableProps) => (
    <div className="overflow-x-auto my-4">
      <table className={cn("min-w-full divide-y divide-purple-700 border border-purple-700 rounded-lg", className)}>
        {children}
      </table>
    </div>
  ),
  thead: ({ children, className }: TableProps) => (
    <thead className={cn("bg-purple-900/40", className)}>
      {children}
    </thead>
  ),
  th: ({ children, className }: TableProps) => (
    <th className={cn("px-6 py-3 text-left text-xs font-medium text-purple-300 uppercase tracking-wider", className)}>
      {children}
    </th>
  ),
  td: ({ children, className }: TableProps) => (
    <td className={cn("px-6 py-4 whitespace-nowrap text-sm text-gray-200 border-t border-purple-700/30", className)}>
      {children}
    </td>
  )
};

// Add proper error handling for file downloads
const handleDownload = async (section: TemplateContent) => {
  try {
    if (section.type === 'file') {
      // Parse the content safely
      let fileData;
      try {
        fileData = JSON.parse(section.content);
      } catch (e) {
        console.error('Invalid file data:', e);
        throw new Error('Invalid file data');
      }

      // Check if we have a valid URL
      if (fileData?.url) {
        // Create blob URL for secure downloads
        const response = await fetch(fileData.url);
        if (!response.ok) throw new Error('Failed to fetch file');
        
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileData.name || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Cleanup
        window.URL.revokeObjectURL(blobUrl);
        return;
      }
    }

    // Fallback to direct API download with error handling
    const response = await fetch(`/api/files/download/${section.id}`);
    if (!response.ok) throw new Error('Failed to download file');
    
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = section.name || 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    console.error('Download error:', error);
    toast({
      title: "Download failed",
      description: "Unable to download the file. Please try again.",
      variant: "destructive",
    });
  }
};

// Update the file section rendering with better error handling
const renderFileSection = (section: TemplateContent) => {
  if (!section.fileUrl && !section.content) {
    return (
      <div className="p-4 border border-red-500 rounded-lg bg-red-500/10">
        <p className="text-red-400">File data not available</p>
      </div>
    );
  }

  let fileUrl = section.fileUrl;
  if (!fileUrl && section.content) {
    try {
      const contentData = typeof section.content === 'string' && section.content.startsWith('{') 
        ? JSON.parse(section.content)
        : { url: section.content };
      fileUrl = contentData.url;
    } catch (e) {
      fileUrl = section.content;
    }
  }

  return (
    <FilePreview
      filename={section.name || section.title || 'file'}
      fileUrl={fileUrl || ''}
      fileSize={section.fileSize}
      onError={(error) => {
        console.error('File preview error:', error);
        toast({
          title: "Preview Error",
          description: "Unable to preview file. Please try downloading instead.",
          variant: "destructive",
        });
      }}
    />
  );
};

export default function ViewTemplate({ 
  id,
  onEdit,
  onBack
}: {
  id: string;
  onEdit: () => void;
  onBack: () => void;
}) {
  const { user: profile } = useAuth();
  const { toast } = useToast();
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [openSections, setOpenSections] = useState<Record<number, boolean>>({});

  const { data: template } = useQuery({
    queryKey: ['template', id],
    queryFn: async () => {
      const response = await fetch(`/api/templates/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch template');
      }
      const data: Template = await response.json();
      return data;
    }
  });

  const hasAccess = useMemo(() => {
    if (!template || !profile) return false;
    if (profile.isAdmin) return true;
    if (profile.tier === UserTierValues.ultimate) return true;
    if (template.requiredTier === 'freemium') return true;
    if (
      template.requiredTier === 'premium' && 
      profile.tier === UserTierValues.premium && 
      profile.subscriptionStatus === 'active'
    ) return true;
    return false;
  }, [profile, template]);

  const isAdmin = useMemo(() => {
    return profile?.isAdmin && profile.tier === UserTierValues.ultimate;
  }, [profile]);

  const handleCopy = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      toast({
        title: "Copied to clipboard",
        description: "Content has been copied to your clipboard",
      });
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Please try again",
        variant: "destructive",
      });
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: template?.title || 'Template',
          text: template?.description || '',
          url: window.location.href,
        });
        
        toast({
          title: "Shared successfully",
          description: "The template has been shared."
        });
      } catch (err) {
        if ((err as Error).name !== 'AbortError') {
          toast({
            title: "Share failed",
            description: "Failed to share the template.",
            variant: "destructive"
          });
        }
      }
    } else {
      // Fallback for browsers that don't support native sharing
      try {
        await navigator.clipboard.writeText(window.location.href);
        toast({
          title: "Link copied",
          description: "Template link has been copied to clipboard."
        });
      } catch (err) {
        toast({
          title: "Copy failed",
          description: "Failed to copy the template link.",
          variant: "destructive"
        });
      }
    }
  };

  const handleUpgrade = async () => {
    if (!profile) {
      setShowAuthDialog(true);
      return;
    }
    
    try {
      await createSubscriptionCheckout();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to start checkout process. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setShowAuthDialog(false);
      toast({
        title: "Welcome back!",
        description: "You've successfully signed in.",
      });
    } catch (error) {
      toast({
        title: "Sign in failed",
        description: "Please try again or contact support.",
        variant: "destructive",
      });
    }
  };

  const toggleSection = (index: number) => {
    setOpenSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  if (!template) return null;

  return (
    <div className="container max-w-4xl mx-auto p-6 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button 
            variant="ghost" 
            size="sm"
            onClick={onBack}
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-cyan-400">
            {template.title}
          </h1>
        </div>
        <div className="flex gap-2">
          {hasAccess && (
            <>
              <Button 
                variant="outline" 
                size="sm"
                className="border-purple-500 text-purple-300 hover:bg-purple-600 hover:text-white"
                onClick={handleShare}
              >
                <Share2 className="w-4 h-4 mr-2" />
                Share
              </Button>
              <Button 
                variant="outline" 
                size="sm"
                className="border-purple-500 text-purple-300 hover:bg-purple-600 hover:text-white"
                onClick={() => handleCopy(JSON.stringify(template.content))}
              >
                <Copy className="w-4 h-4 mr-2" />
                Copy
              </Button>
            </>
          )}
          {isAdmin && (
            <Button 
              onClick={onEdit} 
              size="sm"
              className="border-purple-500 text-purple-300 hover:bg-purple-600 hover:text-white"
            >
              <Edit className="w-4 h-4 mr-2" />
              Edit
            </Button>
          )}
        </div>
      </div>

      {/* Description */}
      {template.description && (
        <Card className="p-4 bg-gradient-to-r from-black via-purple-900/40 to-black border-white/10 rounded-xl shadow-lg">
          <p className="text-purple-200 leading-relaxed">
            {template.description}
          </p>
        </Card>
      )}

      {/* Content */}
      <div 
        className={cn(
          "transition-all duration-200 prose prose-invert max-w-none font-sans text-gray-200",
          !hasAccess && "blur-md cursor-not-allowed pointer-events-none select-none"
        )}
      >
        {template.content.map((section, index) => {
          const isOpen = openSections[index] ?? false;
          
          // Function to check if string is a YouTube URL
          const isYoutubeUrl = (str: string) => {
            return str.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
          };

          // Function to get YouTube video ID
          const getYoutubeVideoId = (url: string) => {
            const match = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
            return match ? match[1] : null;
          };

          return (
            <div 
              key={index} 
              className="mb-6 last:mb-0 border border-purple-700 rounded-xl overflow-hidden bg-gradient-to-b from-purple-900/40 via-black to-black p-4 shadow-xl"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold text-purple-300">
                  {section.title ?? `Section ${index + 1}`}
                </h3>
                <Button 
                  variant="ghost"
                  size="sm"
                  className="text-purple-300 hover:text-purple-100"
                  onClick={() => toggleSection(index)}
                >
                  {isOpen ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                </Button>
              </div>
              {isOpen && (
                <div className="mt-4 space-y-4">
                  {/* Section Actions */}
                  <div className="flex justify-end gap-2 mb-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      className="text-purple-300 hover:text-purple-100"
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(section.content);
                          toast({
                            title: "Copied to clipboard",
                            description: `${section.type === 'code' ? 'Code' : 
                                               section.type === 'link' ? 'Link' : 
                                               section.type === 'file' ? 'Content' : 
                                               'Text'} has been copied to your clipboard`,
                          });
                        } catch (err) {
                          toast({
                            title: "Failed to copy",
                            description: "Please try again",
                            variant: "destructive",
                          });
                        }
                      }}
                    >
                      <Copy className="w-4 h-4 mr-1" />
                      Copy {section.type === 'code' ? 'Code' : 
                            section.type === 'link' ? 'Link' : 
                            section.type === 'file' ? 'Content' : 
                            'Text'}
                    </Button>
                    {section.type !== 'link' && (
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-purple-300 hover:text-purple-100"
                        onClick={() => handleDownload(section)}
                      >
                        <Download className="w-4 h-4 mr-1" />
                        Download
                      </Button>
                    )}
                  </div>
                  
                  {/* Content Rendering based on type */}
                  {isYoutubeUrl(section.content) ? (
                    // YouTube Embed
                    <div className="aspect-video rounded-lg overflow-hidden">
                      <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${getYoutubeVideoId(section.content)}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : section.type === 'file' ? (
                    <div className="relative">
                      {renderFileSection(section)}
                      <div className="mt-2 flex justify-end gap-2">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-purple-300 hover:text-purple-100"
                          onClick={() => handleDownload(section)}
                        >
                          <Download className="w-4 h-4 mr-1" />
                          Download
                        </Button>
                      </div>
                    </div>
                  ) : section.type === 'code' ? (
                    <div className="overflow-hidden rounded-lg border border-purple-700 shadow-md">
                      <SyntaxHighlighter
                        language={
                          section.language ?? getFileExtension(section.name || '') ?? 'javascript'
                        }
                        style={atomOneDark}
                        customStyle={{ background: '#1e1e2f' }}
                        showLineNumbers
                      >
                        {section.content}
                      </SyntaxHighlighter>
                    </div>
                  ) : section.type === 'link' ? (
                    <div className="prose prose-invert max-w-none">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                      >
                        {section.content}
                      </ReactMarkdown>
                    </div>
                  ) : (
                    <div className="prose prose-invert max-w-none">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={markdownComponents}
                      >
                        {section.content}
                      </ReactMarkdown>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* README Section */}
      {template.readme && hasAccess && (
        <div className="mt-8 border border-purple-700 rounded-xl overflow-hidden bg-gradient-to-b from-purple-900/40 via-black to-black p-6 shadow-xl">
          <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">
            Documentation
          </h2>
          <div className="prose prose-invert max-w-none">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={markdownComponents}
            >
              {template.readme}
            </ReactMarkdown>
          </div>
        </div>
      )}

      {/* Upgrade CTA if no access */}
      {!hasAccess && (
        <div className="text-center mt-6 space-y-4">
          <Button 
            variant="gradient" 
            size="lg" 
            onClick={handleUpgrade}
            className="bg-gradient-to-r from-purple-600 to-cyan-500 text-white font-bold shadow-lg hover:from-purple-700 hover:to-cyan-600"
          >
            Upgrade to Access
          </Button>
          {!profile && (
            <p className="text-sm text-purple-200">
              Already have an account?{" "}
              <Button 
                variant="link" 
                className="text-cyan-400 p-0 h-auto underline hover:text-cyan-200" 
                onClick={() => setShowAuthDialog(true)}
              >
                Sign in
              </Button>
            </p>
          )}
        </div>
      )}

      {/* Auth Dialog */}
      <Dialog open={showAuthDialog} onOpenChange={setShowAuthDialog}>
        <DialogContent className="bg-black/90 border border-purple-700 shadow-xl rounded-xl">
          <DialogHeader>
            <DialogTitle className="text-purple-300">Get Access to Premium Content</DialogTitle>
            <DialogDescription className="text-purple-200">
              Sign in to your account or subscribe to unlock premium templates.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4 mt-4">
            <Button 
              onClick={handleSignIn}
              className="bg-purple-700 hover:bg-purple-600 text-white"
            >
              Sign in with Google
            </Button>
            <div className="relative my-2">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t border-purple-700" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-black px-2 text-purple-300">
                  Or
                </span>
              </div>
            </div>
            <Button 
              variant="gradient"
              onClick={() => {
                setShowAuthDialog(false);
                handleUpgrade();
              }}
              className="bg-gradient-to-r from-purple-700 to-cyan-500 text-white font-semibold hover:from-purple-800 hover:to-cyan-600"
            >
              Subscribe Now
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}