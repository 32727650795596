import { Link } from "wouter";
import { Button } from "@/components/ui/button";
import { auth } from "@/lib/firebase";
import { getTierBadgeColor, getTierLabel } from "@/types/auth";
import { useAuth } from '@/lib/auth';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X } from "lucide-react";
import { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { UserTierValues } from "@/types/shared";
import { UserProfile } from "@/components/UserProfile";

export default function Navigation() {
  const { user, loading } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Sign in error:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setIsMobileMenuOpen(false);
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const navLinks = [
    { href: "/pricing", label: "Pricing" },
    { href: "/#templates", label: "Templates" },
    ...(user?.isAdmin ? [{ href: "/admin", label: "Admin" }] : []),
  ];

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled 
          ? 'bg-background/80 backdrop-blur-lg shadow-lg shadow-black/5' 
          : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="h-16 flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center gap-8">
            <Link href="/" className="flex items-center gap-2">
              <img 
                src="/uploads/logo.png" 
                alt="AI Playgrounds"
                width={32}
                height={32}
                className="h-8 w-auto object-contain"
                onError={(e) => {
                  console.error('Logo failed to load:', e);
                  e.currentTarget.style.display = 'none';
                }}
              />
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center gap-6">
              {navLinks.map((link) => (
                <Link 
                  key={link.href}
                  href={link.href} 
                  className="text-sm font-medium text-muted-foreground hover:text-primary transition-colors"
                >
                  {link.label}
                </Link>
              ))}
            </nav>
          </div>

          {/* Desktop Actions */}
          <div className="hidden md:flex items-center gap-4">
            {user?.isAdmin && user?.tier === UserTierValues.ultimate && (
              <Link href="/builder/new">
                <Button 
                  variant="outline"
                  className="border-primary/50 hover:border-primary/75 bg-black/50 backdrop-blur-sm transition-all duration-300 hover:bg-primary/20"
                >
                  Create Template
                </Button>
              </Link>
            )}
            
            {!user ? (
              <Button
                variant="glass"
                onClick={handleSignIn}
                className="flex items-center gap-2"
              >
                <svg 
                  className="w-5 h-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" 
                  />
                </svg>
                Sign In
              </Button>
            ) : (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="glass" 
                    className="flex items-center gap-2 px-3 py-2"
                  >
                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-primary to-purple-600 flex items-center justify-center text-white font-medium">
                      {user.email?.[0].toUpperCase()}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className={`px-3 py-1 rounded-full text-sm font-medium ${getTierBadgeColor(user.tier)}`}>
                        {getTierLabel(user.tier)}
                      </div>
                      <div className={`px-2 py-1 rounded-full text-xs font-medium
                        ${user.subscriptionStatus === 'active' ? 'bg-green-500/20 text-green-500' : 
                          user.subscriptionStatus === 'trialing' ? 'bg-blue-500/20 text-blue-500' : 
                          user.subscriptionStatus === 'canceled' ? 'bg-red-500/20 text-red-500' : 
                          'bg-gray-500/20 text-gray-500'}`}>
                        {user.subscriptionStatus === 'active' ? 'Active' :
                         user.subscriptionStatus === 'trialing' ? 'Trial' :
                         user.subscriptionStatus === 'canceled' ? 'Canceled' : 'None'}
                      </div>
                    </div>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-72">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <div className="px-2 py-2">
                    <UserProfile />
                  </div>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={handleSignOut} className="text-red-500 focus:text-red-500">
                    Sign Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-white/10 transition-colors"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden border-t border-white/10 bg-black/95 backdrop-blur-xl absolute top-16 left-0 right-0 shadow-xl border-b border-purple-500/20"
            >
              <div className="container mx-auto">
                <div className="py-4 space-y-4">
                  {/* Mobile Navigation Links */}
                  <nav className="flex flex-col gap-2">
                    {navLinks.map((link) => (
                      <Link
                        key={link.href}
                        href={link.href}
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="px-4 py-2 text-sm font-medium text-muted-foreground hover:text-primary hover:bg-purple-500/10 rounded-lg transition-colors"
                      >
                        {link.label}
                      </Link>
                    ))}
                  </nav>

                  {/* Mobile Actions */}
                  <div className="px-4 space-y-2">
                    {user?.isAdmin && user?.tier === UserTierValues.ultimate && (
                      <Link href="/builder/new" onClick={() => setIsMobileMenuOpen(false)}>
                        <Button 
                          variant="outline"
                          className="w-full border-primary/50 hover:border-primary/75 bg-black/50 backdrop-blur-sm"
                        >
                          Create Template
                        </Button>
                      </Link>
                    )}
                    
                    {!user ? (
                      <Button
                        variant="glass"
                        onClick={handleSignIn}
                        className="w-full flex items-center justify-center gap-2 bg-purple-500/10 hover:bg-purple-500/20"
                      >
                        <svg 
                          className="w-5 h-5" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" 
                          />
                        </svg>
                        Sign In
                      </Button>
                    ) : (
                      <div className="space-y-2">
                        <div className="flex items-center gap-3 px-4 py-2 rounded-lg bg-purple-500/5 border border-purple-500/10">
                          <div className="w-8 h-8 rounded-full bg-gradient-to-r from-primary to-purple-600 flex items-center justify-center text-white font-medium">
                            {user.email?.[0].toUpperCase()}
                          </div>
                          <div>
                            <div className="text-sm font-medium">{user.email}</div>
                            <div className="flex items-center gap-2">
                              <div className={`px-2 py-1 rounded-full text-xs font-medium ${getTierBadgeColor(user.tier)}`}>
                                {getTierLabel(user.tier)}
                              </div>
                              <div className={`px-2 py-1 rounded-full text-xs font-medium
                                ${user.subscriptionStatus === 'active' ? 'bg-green-500/20 text-green-500' : 
                                  user.subscriptionStatus === 'trialing' ? 'bg-blue-500/20 text-blue-500' : 
                                  user.subscriptionStatus === 'canceled' ? 'bg-red-500/20 text-red-500' : 
                                  'bg-gray-500/20 text-gray-500'}`}>
                                {user.subscriptionStatus === 'active' ? 'Active' :
                                 user.subscriptionStatus === 'trialing' ? 'Trial' :
                                 user.subscriptionStatus === 'canceled' ? 'Canceled' : 'None'}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="destructive"
                          onClick={handleSignOut}
                          className="w-full justify-start text-red-500 hover:text-red-400 bg-red-500/5 hover:bg-red-500/10"
                        >
                          Sign Out
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
}