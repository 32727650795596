import { useMutation } from "@tanstack/react-query";
import { useState, useRef } from "react";
import { motion } from "framer-motion";
import { formFieldVariants, previewVariants } from "@/lib/animations";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import DragDropBuilder from "./DragDropBuilder";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TemplateTierValues, type TemplateTier } from "@/types/shared";

interface CreateTemplateProps {
  onCreated: (id: number) => void;
  onCancel: () => void;
}

export default function CreateTemplate({ onCreated, onCancel }: CreateTemplateProps) {
  const { toast } = useToast();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [readme, setReadme] = useState("");
  const [content, setContent] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [tier, setTier] = useState<TemplateTier>(TemplateTierValues.freemium);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const createMutation = useMutation({
    mutationFn: async () => {
      if (!title.trim()) {
        throw new Error("Title is required");
      }

      // Validate content structure
      const validatedContent = content.map(item => {
        if (!item.id || !item.type || !item.content) {
          throw new Error('Invalid content item structure');
        }
        if (!['text', 'code', 'link', 'file'].includes(item.type)) {
          throw new Error(`Invalid content type: ${item.type}`);
        }
        // Ensure all required fields are present
        return {
          id: item.id,
          type: item.type,
          content: item.content,
          name: item.name || null,
          title: item.title || null,
          fileUrl: item.fileUrl || null,
          fileSize: item.fileSize || null,
          filePath: item.filePath || null,
          fileType: item.fileType || null
        };
      });

      // Match server schema exactly
      const templateData = {
        title: title.trim(),
        description: description.trim() || null,
        content: validatedContent,
        tier: tier,
        requiredTier: tier, // Set same as tier
        readme: readme.trim() || null,
        isLocked: false,
        views: 0,
        shares: 0,
        features: {}
      };

      const response = await fetch("/api/templates", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateData)
      });

      if (!response.ok) {
        const error = await response.json().catch(() => ({ error: "Failed to create template" }));
        throw new Error(error.error || "Failed to create template");
      }

      return response.json();
    },
    onSuccess: (data) => {
      toast({ title: "Template created successfully" });
      onCreated(data.id);
    },
    onError: (error: Error) => {
      toast({ 
        title: error.message || "Failed to create template", 
        variant: "destructive" 
      });
    },
  });

  const handleThumbnailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setThumbnail(e.target.files[0]);
    }
  };

  return (
    <div className="container mx-auto py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold">Create New Template</h1>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={() => createMutation.mutate()}
            disabled={createMutation.isPending}
          >
            Create Template
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-6">
          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6 space-y-4">
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">
                  Basic Information
                </h2>
                <div className="space-y-4">
                  <motion.div variants={formFieldVariants}>
                    <Input
                      placeholder="Template Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="text-lg bg-black/30 border-white/10 focus:border-primary/50"
                    />
                  </motion.div>
                  <Textarea
                    placeholder="Template Description - Brief overview of what this template does"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="h-24 bg-black/30 border-white/10 focus:border-primary/50"
                  />
                  <div className="space-y-2">
                    <label className="block text-sm font-medium">Template Tier</label>
                    <Select
                      value={tier}
                      onValueChange={(value) => setTier(value as TemplateTier)}
                    >
                      <SelectTrigger className="bg-black/30 border-white/10">
                        <SelectValue placeholder="Select tier" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(TemplateTierValues).map(([key, value]) => (
                          <SelectItem key={key} value={value}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">Thumbnail Image</label>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleThumbnailChange}
                      accept="image/*"
                      className="hidden"
                    />
                    <div className="flex gap-4 items-center">
                      {thumbnail && (
                        <img
                          src={URL.createObjectURL(thumbnail)}
                          alt="Thumbnail preview"
                          className="w-24 h-24 object-cover rounded-lg"
                        />
                      )}
                      <Button
                        type="button"
                        variant="outline"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        {thumbnail ? 'Change Thumbnail' : 'Upload Thumbnail'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="text-2xl font-semibold bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Documentation</h2>
                <Textarea
                  placeholder="README - Add detailed instructions, requirements, use cases, and any other relevant information (Markdown supported)"
                  value={readme}
                  onChange={(e) => setReadme(e.target.value)}
                  className="h-48 font-mono text-sm bg-black/30 border-white/10 focus:border-primary/50"
                />
              </div>
            </CardContent>
          </Card>

          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Template Builder</h2>
              <DragDropBuilder 
                initialContent={content}
                onChange={setContent}
              />
            </CardContent>
          </Card>
        </div>

        <div className="space-y-6">
          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Content Preview</h2>
              <motion.div 
                className="prose prose-invert max-w-none"
                variants={previewVariants}
                initial="initial"
                animate="animate"
              >
                {readme && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">README Preview</h3>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {readme}
                    </ReactMarkdown>
                  </div>
                )}
                {content.length > 0 && (
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Content Preview</h3>
                    <DragDropBuilder
                      initialContent={content}
                      readOnly={true}
                    />
                  </div>
                )}
              </motion.div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
