import { StrictMode, lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Switch, Route } from "wouter";
import { ErrorBoundary } from "./components/ErrorBoundary";
import "./index.css";
import "./styles/theme.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/queryClient";
import { Toaster } from "@/components/ui/toaster";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

// Lazy load route components to reduce initial bundle size
const Home = lazy(() => import("./pages/Home"));
const Admin = lazy(() => import("./pages/Admin"));
const Builder = lazy(() => import("./pages/Builder"));
const Pricing = lazy(() => import("./pages/Pricing"));

function AppRouter() {
  return (
    <div className="flex flex-col min-h-screen bg-background/50 relative overflow-hidden">
      {/* Background layers */}
      <div className="fixed inset-0 z-0">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-background/50 to-background/80 animate-gradient" />

        {/* Grid pattern */}
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDI1NSwyNTUsMjU1LDAuMDUpIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=')] opacity-20" />

        {/* Glow effects */}
        <div className="absolute inset-0 pointer-events-none mix-blend-screen">
          <div className="absolute top-1/4 left-1/4 w-[40rem] h-[40rem] bg-purple-500/30 rounded-full blur-3xl animate-glow" />
          <div className="absolute bottom-1/4 right-1/4 w-[35rem] h-[35rem] bg-blue-500/30 rounded-full blur-3xl animate-glow" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[45rem] h-[45rem] bg-violet-500/20 rounded-full blur-3xl animate-glow" />
        </div>
      </div>

      {/* Content layer */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <Navigation />

        <main className="flex-grow pt-16">
          <Suspense fallback={
            <div className="flex items-center justify-center min-h-screen">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
            </div>
          }>
            <Switch>
              <Route path="/" component={Home} />
              <Route path="/admin" component={Admin} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/builder/:id/edit" component={Builder} />
              <Route path="/builder/:id" component={Builder} />
              <Route path="/builder/new" component={Builder} />
              <Route>404 Page Not Found</Route>
            </Switch>
          </Suspense>
        </main>

        <Footer />
      </div>
    </div>
  );
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <ErrorBoundary>
          <AppRouter />
          <Toaster />
        </ErrorBoundary>
      </DndProvider>
    </QueryClientProvider>
  </StrictMode>
);