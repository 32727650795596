import type { Variants } from 'framer-motion';

// Card hover and selection animations
export const cardVariants: Variants = {
  initial: {
    scale: 1,
    opacity: 0,
    y: 20,
  },
  animate: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hover: {
    scale: 1.01,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  selected: {
    scale: 1,
    boxShadow: '0 0 0 2px var(--primary)',
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
};

// Form field animations with validation states
export const formFieldVariants: Variants = {
  initial: { opacity: 0, x: -20 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  error: {
    x: [-2, 2, -2, 2, 0],
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
  success: {
    scale: [1, 1.02, 1],
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

// Content preview animations with loading state
export const previewVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
  loading: {
    opacity: 0.5,
    transition: {
      duration: 0.2,
    },
  },
};

// Staggered list animations for template grid
export const containerVariants: Variants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

// Modal animations with attention states
export const modalVariants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    y: 20,
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: 20,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
  attention: {
    scale: [1, 1.02, 1],
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

// Button interaction animations
export const buttonVariants: Variants = {
  initial: { scale: 1 },
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
  tap: {
    scale: 0.95,
    transition: {
      duration: 0.1,
    },
  },
  disabled: {
    opacity: 0.6,
    scale: 1,
    transition: {
      duration: 0.2,
    },
  },
};

// Navigation page transitions
export const pageTransitionVariants: Variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

// Loading spinner animation
export const spinnerVariants: Variants = {
  animate: {
    rotate: 360,
    transition: {
      duration: 1,
      ease: 'linear',
      repeat: Infinity,
    },
  },
};
