import { useDrag, useDrop } from 'react-dnd';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import ReactMarkdown from 'react-markdown';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { fileStorage, type StoredFile } from '@/lib/fileStorage';
import { useToast } from "@/hooks/use-toast";
import { 
  FileText, 
  Code2, 
  Link as LinkIcon, 
  FolderUp,
  ChevronDown,
  ChevronRight,
  Trash2,
  Download,
  Folder
} from 'lucide-react';

const ITEM_TYPES = {
  TEXT: 'text',
  CODE: 'code',
  LINK: 'link',
  FILE: 'file'
} as const;

// Add allowed file types
const ALLOWED_FILE_TYPES = [
  // Code files
  '.js', '.jsx', '.ts', '.tsx', '.py', '.html', '.css', '.json', '.md',
  // Image files
  '.jpg', '.jpeg', '.png', '.gif', '.svg',
  // Document files
  '.pdf', '.doc', '.docx', '.txt'
];

type ItemType = typeof ITEM_TYPES[keyof typeof ITEM_TYPES];

interface BuilderItem {
  id: number;
  type: ItemType;
  content: string;
  fileData?: StoredFile;
}

interface BuilderProps {
  initialContent?: BuilderItem[];
  onChange?: (content: BuilderItem[]) => void;
  readOnly?: boolean;
  templateId?: number;
  onFileUpload?: (file: StoredFile) => Promise<void>;
  onRemove?: (id: string) => void;
}

export default function DragDropBuilder({
  initialContent = [],
  onChange,
  readOnly = false,
  templateId,
  onFileUpload
}: BuilderProps) {
  const { toast } = useToast();

  // Process initialContent only once during component initialization
  const [items, setItems] = useState<BuilderItem[]>(() => {
    if (!Array.isArray(initialContent)) return [];
    return initialContent.map((item, index) => ({
      id: item?.id || Date.now() + index,
      type: (item?.type && Object.values(ITEM_TYPES).includes(item.type)) ? item.type : ITEM_TYPES.TEXT,
      content: item?.content || ''
    }));
  });

  // Memoized onChange handler
  const handleItemsChange = useCallback((newItems: BuilderItem[]) => {
    if (onChange) {
      onChange(newItems);
    }
  }, [onChange]);

  // Update parent only when items actually change
  useEffect(() => {
    handleItemsChange(items);
  }, [items, handleItemsChange]);

  const [, drop] = useDrop(() => ({
    accept: Object.values(ITEM_TYPES),
    drop: (item: BuilderItem, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) return;

      const newItem: BuilderItem = {
        id: Date.now(),
        type: item.type,
        content: ''
      };
      setItems(prev => [...prev, newItem]);
    }
  }));

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const dragItem = newItems[dragIndex];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);
      return newItems;
    });
  }, []);

  const addItem = useCallback((type: ItemType) => {
    const newItem: BuilderItem = {
      id: Date.now(),
      type,
      content: ''
    };
    setItems(prev => [...prev, newItem]);
  }, []);

  const updateItem = useCallback((id: number, content: string) => {
    setItems(prev =>
      prev.map(item => item.id === id ? { ...item, content } : item)
    );
  }, []);

  const removeItem = useCallback((id: number) => {
    const itemToRemove = items.find(item => item.id === id);
    console.log('Content section operation:', { 
      operation: 'delete',
      id, 
      type: itemToRemove?.type,
      content: itemToRemove?.content,
      timestamp: new Date().toISOString(),
      totalItems: items.length
    });
    
    // Update items state and notify parent
    setItems(prev => {
      const newItems = prev.filter(item => item.id !== id);
      if (onChange) {
        onChange(newItems);
      }
      return newItems;
    });
  }, [items, onChange]);

  return (
    <div
      ref={drop}
      className={`min-h-[500px] p-4 border border-white/10 rounded-lg bg-black/20 transition-colors duration-300 ${!readOnly ? 'hover:border-primary/50' : ''}`}
    >
      {!readOnly && (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <button
            onClick={() => addItem('text')}
            className="icon-button-gradient group"
          >
            <FileText className="icon text-primary" />
            <span className="icon-label">Add Text</span>
          </button>

          <button
            onClick={() => addItem('code')}
            className="icon-button-gradient group"
          >
            <Code2 className="icon text-primary" />
            <span className="icon-label">Add Code</span>
          </button>

          <button
            onClick={() => addItem('link')}
            className="icon-button-gradient group"
          >
            <LinkIcon className="icon text-primary" />
            <span className="icon-label">Add Link</span>
          </button>

          <button
            onClick={() => addItem('file')}
            className="icon-button-gradient group"
          >
            <FolderUp className="icon text-primary" />
            <span className="icon-label">Add File</span>
          </button>
        </div>
      )}

      <div className="space-y-4">
        {items.map((item, index) => (
          <DraggableItem
            key={item.id}
            item={item}
            index={index}
            onUpdate={updateItem}
            onRemove={removeItem}
            moveItem={moveItem}
            templateId={templateId}
            onFileUpload={onFileUpload}
          />
        ))}
      </div>
    </div>
  );
}

interface DraggableItemProps {
  item: BuilderItem;
  index: number;
  onUpdate: (id: number, content: string) => void;
  onRemove: (id: number) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  templateId?: number;
  onFileUpload?: (file: StoredFile) => Promise<void>;
}

function DraggableItem({
  item,
  index,
  onUpdate,
  onRemove,
  moveItem,
  templateId,
  onFileUpload
}: DraggableItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { toast } = useToast();

  const [{ isDragging }, drag] = useDrag({
    type: item.type,
    item: () => ({ ...item, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: Object.values(ITEM_TYPES),
    hover(draggedItem: BuilderItem & { index: number }, monitor) {
      if (!ref.current || draggedItem.id === item.id) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    }
  });

  drag(drop(ref));

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('[FileUpload] Starting file upload');
    const file = e.target.files?.[0];
    if (!file) {
      console.log('[FileUpload] No file selected');
      return;
    }

    const ext = getFileExtension(file.name);
    console.log('[FileUpload] File details:', {
      name: file.name,
      size: file.size,
      type: file.type,
      extension: ext
    });

    if (!ALLOWED_FILE_TYPES.includes(ext)) {
      console.error('[FileUpload] Invalid file type:', ext);
      toast({
        title: "Invalid file type",
        description: `Only ${ALLOWED_FILE_TYPES.join(', ')} files are allowed`,
        variant: "destructive"
      });
      return;
    }

    const maxRetries = 3;
    let retryCount = 0;
    let uploadSuccess = false;

    while (!uploadSuccess && retryCount < maxRetries) {
      try {
        // Create FormData and append file with metadata
        const formData = new FormData();
        formData.append('file', file);
        formData.append('templateId', (templateId || -1).toString());
        formData.append('sectionId', item.id.toString());
        formData.append('originalType', file.type); // Preserve original type

        console.log('Uploading file:', {
          fileName: file.name,
          templateId: templateId || -1,
          sectionId: item.id,
          attempt: retryCount + 1
        });

        // Upload file to server with improved timeout and keepalive
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 600000); // 10 minutes timeout to match server

        console.log('[FileUpload] Sending fetch request');
        const response = await fetch('/api/files/upload', {
          method: 'POST',
          body: formData,
          signal: controller.signal,
          keepalive: true,
          headers: {
            'Connection': 'keep-alive'
          }
        })
          .then(res => {
            console.log('[FileUpload] Response received:', {
              status: res.status,
              statusText: res.statusText,
              headers: Object.fromEntries(res.headers.entries())
            });
            return res;
          })
          .catch(err => {
            console.error('[FileUpload] Fetch error:', err);
            throw err;
          })
          .finally(() => {
            console.log('[FileUpload] Request completed');
            clearTimeout(timeoutId);
          });

        // Check response content type
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          const errorText = await response.text();
          console.error('Server response:', {
            status: response.status,
            headers: Object.fromEntries(response.headers.entries()),
            body: errorText
          });
          throw new Error('Server returned non-JSON response');
        }

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response body:', errorText);

          let errorMessage = `Upload failed (${response.status}): ${response.statusText}`;
          try {
            const errorJson = JSON.parse(errorText);
            errorMessage = errorJson.error || errorMessage;
          } catch {
            console.error('Failed to parse error response as JSON');
          }
          throw new Error(errorMessage);
        }

        const fileData = await response.json();
        console.log('Upload successful:', fileData);

        if (!fileData.id || !fileData.url) {
          throw new Error('Invalid response from server');
        }

        // Update item with file reference and preserve type information
        const fileInfo = {
          id: fileData.id,
          name: file.name,
          url: fileData.url,
          type: file.type, // Original type
          size: file.size,
          extension: ext,
          uploadedAt: new Date().toISOString()
        };
        console.log('Updating item with file info:', fileInfo);

        onUpdate(item.id, JSON.stringify(fileInfo));
        uploadSuccess = true;

        toast({
          title: "File uploaded successfully",
          description: `${file.name} has been uploaded`
        });

        break; // Exit retry loop on success

      } catch (error) {
        console.error('File upload error:', error);
        retryCount++;

        if (retryCount === maxRetries) {
          toast({
            title: "Error uploading file",
            description: `Failed after ${maxRetries} attempts. Please try again.`,
            variant: "destructive"
          });
        } else {
          // Only show retry toast if we're going to retry
          toast({
            title: "Retrying upload",
            description: `Attempt ${retryCount + 1} of ${maxRetries}`,
            variant: "default"
          });

          // Wait before retrying (exponential backoff)
          await new Promise(resolve => setTimeout(resolve, Math.min(1000 * Math.pow(2, retryCount), 10000)));
        }
      }
    }
  };

  const handleFileDownload = async () => {
    try {
      const fileData = JSON.parse(item.content);

      if (fileData.isLocal) {
        // Handle local file download
        const storedFile = await fileStorage.getFile(fileData.id);
        if (!storedFile) {
          throw new Error('File not found locally');
        }
        const url = URL.createObjectURL(storedFile.file);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileData.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        // Handle template file download
        const response = await fetch(`/api/files/download/${fileData.id}`);
        if (!response.ok) {
          throw new Error('Failed to download file');
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileData.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      toast({
        title: "Error downloading file",
        description: "Could not download the file",
        variant: "destructive"
      });
    }
  };

  return (
    <Card
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="cursor-move group relative backdrop-blur-lg bg-black/30 border-white/10 hover:border-primary/50 transition-all duration-300 hover:scale-[1.02] hover:shadow-[0_0_30px_rgba(124,58,237,0.1)]"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      <CardContent className="p-4 relative">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                setIsCollapsed(!isCollapsed);
              }}
              className="text-white/70 hover:text-white"
            >
              {isCollapsed ? <ChevronRight className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
            </Button>
            <span className="text-sm text-white/70 flex items-center gap-2">
              {item.type === ITEM_TYPES.TEXT && <><FileText className="w-4 h-4 text-primary" /> Text</>}
              {item.type === ITEM_TYPES.CODE && <><Code2 className="w-4 h-4 text-primary" /> Code</>}
              {item.type === ITEM_TYPES.LINK && <><LinkIcon className="w-4 h-4 text-primary" /> Link</>}
              {item.type === ITEM_TYPES.FILE && <><FolderUp className="w-4 h-4 text-primary" /> File</>}
            </span>
          </div>
          <Button
            variant="destructive"
            size="sm"
            onClick={() => onRemove(item.id)}
          >
            <Trash2 className="w-4 h-4 mr-2" /> Remove
          </Button>
        </div>

        <div className={`transition-all duration-300 ${isCollapsed ? 'h-0 overflow-hidden' : ''}`}>
          {item.type === ITEM_TYPES.TEXT && (
            <div className="space-y-4">
              <Textarea
                value={item.content}
                onChange={(e) => onUpdate(item.id, e.target.value)}
                placeholder="Enter markdown text..."
                className="bg-black/20 border-white/10 focus:border-primary/50 transition-all duration-300 min-h-[100px] font-mono text-sm"
              />
              <div className="prose prose-invert max-w-none p-4 rounded-lg bg-black/10">
                <ReactMarkdown>{item.content}</ReactMarkdown>
              </div>
            </div>
          )}

          {item.type === ITEM_TYPES.CODE && (
            <div className="space-y-4">
              <Textarea
                value={item.content}
                onChange={(e) => onUpdate(item.id, e.target.value)}
                placeholder="Enter code..."
                className="font-mono bg-black/20 border-white/10 focus:border-primary/50 transition-all duration-300 min-h-[100px]"
              />
              <div className="rounded-lg overflow-hidden">
                <SyntaxHighlighter
                  language="javascript"
                  style={oneDark}
                  className="!m-0"
                  customStyle={{
                    background: 'rgba(0,0,0,0.3)',
                    padding: '1rem',
                    borderRadius: '0.5rem'
                  }}
                >
                  {item.content}
                </SyntaxHighlighter>
              </div>
            </div>
          )}

          {item.type === ITEM_TYPES.LINK && (
            <div className="space-y-4">
              <Input
                value={item.content}
                onChange={(e) => onUpdate(item.id, e.target.value)}
                placeholder="Enter URL..."
                className="bg-black/20 border-white/10 focus:border-primary/50 transition-all duration-300"
              />
              {item.content && (
                <div className="p-4 rounded-lg bg-black/20 border border-white/10 hover:border-primary/50 transition-all duration-300">
                  <a
                    href={item.content}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-4 text-primary hover:text-primary/80 transition-colors"
                  >
                    {(() => {
                      try {
                        if (item.content.startsWith('http')) {
                          const url = new URL(item.content);
                          return (
                            <img
                              src={`https://www.google.com/s2/favicons?domain=${url.hostname}`}
                              alt=""
                              className="w-6 h-6"
                            />
                          );
                        }
                      } catch (e) {
                        // Invalid URL, don't show favicon
                      }
                      return null;
                    })()}
                    {item.content}
                  </a>
                </div>
              )}
            </div>
          )}

          {item.type === ITEM_TYPES.FILE && (
            <div className="space-y-2">
              <Input
                type="file"
                onChange={handleFileUpload}
                accept={ALLOWED_FILE_TYPES.join(',')}
                className="bg-black/20 border-white/10 focus:border-primary/50 transition-all duration-300"
              />
              {item.content && (
                <div className="p-4 rounded-lg bg-black/20 border border-white/10">
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <Folder className="w-5 h-5 text-primary" />
                      <span>{(() => {
                        try {
                          const fileData = JSON.parse(item.content);
                          return fileData.name;
                        } catch {
                          return 'File';
                        }
                      })()}</span>
                    </div>
                    <Button
                      onClick={handleFileDownload}
                      variant="link"
                      className="text-sm text-primary hover:text-primary/80 transition-colors flex items-center gap-2"
                    >
                      <Download className="w-4 h-4" /> Download
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

function getFileExtension(filename: string): string {
  const parts = filename.split('.');
  return parts.length > 1 ? `.${parts[parts.length - 1].toLowerCase()}` : '';
}

function getFileName(filepath: string): string {
  return filepath.split('/').pop() || filepath;
}