import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-lg px-3 py-1.5 text-sm font-semibold transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 select-none",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        premium: [
          "relative overflow-hidden",
          "border border-white/20",
          "bg-black/40 backdrop-blur-md",
          "before:absolute before:inset-0",
          "before:bg-gradient-to-r before:from-purple-600/40 before:via-cyan-500/40 before:to-purple-600/40",
          "before:animate-gradient-x",
          "after:absolute after:inset-0",
          "after:bg-gradient-to-r after:from-transparent after:via-white/20 after:to-transparent",
          "after:animate-shine",
          "hover:scale-105 hover:shadow-lg hover:shadow-purple-500/20",
          "uppercase tracking-wider",
          "transform-gpu transition-all duration-300"
        ],
        ultimate: [
          "relative overflow-hidden",
          "border border-white/20",
          "bg-black/40 backdrop-blur-md",
          "before:absolute before:inset-0",
          "before:bg-gradient-to-r before:from-yellow-600/40 before:via-purple-500/40 before:to-yellow-600/40",
          "before:animate-gradient-x",
          "after:absolute after:inset-0",
          "after:bg-gradient-to-r after:from-transparent after:via-white/20 after:to-transparent",
          "after:animate-shine",
          "hover:scale-105 hover:shadow-lg hover:shadow-yellow-500/20",
          "uppercase tracking-wider",
          "transform-gpu transition-all duration-300"
        ],
        freemium: [
          "relative overflow-hidden",
          "border border-white/20",
          "bg-black/40 backdrop-blur-md",
          "before:absolute before:inset-0",
          "before:bg-gradient-to-r before:from-blue-600/40 before:via-cyan-500/40 before:to-blue-600/40",
          "before:animate-gradient-x",
          "after:absolute after:inset-0",
          "after:bg-gradient-to-r after:from-transparent after:via-white/20 after:to-transparent",
          "after:animate-shine",
          "hover:scale-105 hover:shadow-lg hover:shadow-blue-500/20",
          "uppercase tracking-wider",
          "transform-gpu transition-all duration-300"
        ],
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  tier?: 'freemium' | 'premium' | 'ultimate';
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, tier, children, ...props }, ref) => {
    const finalVariant = tier || variant;
    return (
      <div 
        ref={ref}
        className={cn(
          badgeVariants({ variant: finalVariant }), 
          "group relative",
          className
        )} 
        {...props} 
      >
        <div className="relative z-10">
          {children}
        </div>
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <div className="absolute inset-0 animate-pulse-slow bg-gradient-to-r from-transparent via-white/10 to-transparent" />
        </div>
      </div>
    );
  }
);

Badge.displayName = "Badge";

export { Badge, badgeVariants }
