import React from 'react';
import { getFileIcon } from '@/lib/files';

export interface FilePreviewProps {
  filename: string;
  fileUrl?: string;
  fileSize?: number;
  content?: string;
  type?: 'text' | 'code' | 'link' | 'file' | 'application/json';
  onError?: (error: Error) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ filename, fileUrl, fileSize }) => {
  const FileIcon = getFileIcon(filename);
  
  return (
    <div className="flex items-center space-x-2 p-2 border rounded-md">
      <FileIcon className="w-5 h-5" />
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium truncate">{filename}</p>
        {fileSize && (
          <p className="text-xs text-muted-foreground">
            {(fileSize / 1024).toFixed(1)} KB
          </p>
        )}
      </div>
      {fileUrl && (
        <a
          href={fileUrl}
          download={filename}
          className="text-xs text-primary hover:text-primary/80"
        >
          Download
        </a>
      )}
    </div>
  );
};

export default FilePreview;
