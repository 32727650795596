import { openDB } from 'idb';

export interface StoredFile {
  id: number;
  file: File;  // Store the original File object
}

interface FileDB {
  files: {
    key: number;
    value: StoredFile;
  };
}

export const fileStorage = {
  openDB: async () => {
    return await openDB<FileDB>('fileStorage', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
        }
      }
    });
  },

  saveFile: async (file: File): Promise<StoredFile> => {
    const db = await fileStorage.openDB();
    
    const storedFile: StoredFile = {
      id: Date.now(),
      file: file  // Store the original file without any modifications
    };

    await db.put('files', storedFile);
    return storedFile;
  },

  getFile: async (fileId: number): Promise<StoredFile | undefined> => {
    const db = await fileStorage.openDB();
    return await db.get('files', fileId);
  }
}; 