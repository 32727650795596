import React from 'react';
import { useAuth } from '@/lib/auth';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { getTierLabel, getTierBadgeColor, AuthUser } from '@/types/auth';
import { UserTier } from '@/types/shared';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { Separator } from '@/components/ui/separator';
import { app } from '@/lib/firebase';
import { useToast } from '@/hooks/use-toast';
import type { SubscriptionStatus } from '@/types/auth';

export function UserProfile() {
  const { user } = useAuth();
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);

  if (!user) {
    return null;
  }

  const handleCancelSubscription = async () => {
    if (!user) return;
    
    setLoading(true);
    try {
      const db = getFirestore(app);
      const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
      const snapshot = await getDocs(subscriptionsRef);
      
      if (!snapshot.empty) {
        const subscriptionDoc = snapshot.docs[0];
        const subscriptionId = subscriptionDoc.id;
        
        // Create a cancel subscription document
        const cancelRef = collection(db, 'customers', user.uid, 'cancel_subscriptions');
        await addDoc(cancelRef, {
          subscriptionId,
          timestamp: new Date().toISOString(),
          userId: user.uid,
          status: 'requested'
        });
        
        toast({
          title: "Cancellation Requested",
          description: "Your subscription will be canceled at the end of the billing period.",
        });
      } else {
        toast({
          title: "No Active Subscription",
          description: "No active subscription found to cancel.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      toast({
        title: "Error",
        description: "Failed to cancel subscription. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="p-6 max-w-md mx-auto mt-4">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Profile</h2>
          <Badge 
            variant={user.tier === 'visitor' ? 'secondary' : 
                    user.tier === 'premium' ? 'default' : 
                    user.tier === 'ultimate' ? 'destructive' : 'outline'}
            className={getTierBadgeColor(user.tier)}
          >
            {getTierLabel(user.tier)}
          </Badge>
        </div>
        
        <div className="space-y-4">
          <div className="flex items-center gap-2 mb-2">
            <span className="font-semibold">Email:</span>
            <span>{user.email}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-semibold">Subscription Status:</span>
            <Badge variant={user.subscriptionStatus === 'active' ? 'default' : 'secondary'}>
              {user.subscriptionStatus === 'active' ? 'Active' :
               user.subscriptionStatus === 'trialing' ? 'Trial' :
               user.subscriptionStatus === 'canceled' ? 'Canceled' : 'None'}
            </Badge>
          </div>
          {user.isAdmin && (
            <div>
              <Badge variant="destructive">Admin</Badge>
            </div>
          )}

          {user.subscriptionStatus === 'active' && (
            <Button 
              variant="destructive" 
              onClick={handleCancelSubscription}
              disabled={loading}
              className="w-full"
            >
              {loading ? 'Processing...' : 'Cancel Subscription'}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}