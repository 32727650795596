import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { app } from "./firebase";
import { auth } from "./firebase";
import { SubscriptionStatus } from '../types/auth';


const db = getFirestore(app);

// Premium price ID for the $15/month subscription
const PREMIUM_PRICE_ID = "price_1QS5vuA0bfP6E2srdOalOv6p"; // Updated from logs

// Create a checkout session for subscription
export async function createSubscriptionCheckout() {
  if (!auth.currentUser) {
    throw new Error("Must be logged in to upgrade");
  }

  try {
    const checkoutSessionRef = collection(
      db,
      "customers",
      auth.currentUser.uid,
      "checkout_sessions"
    );
    
    // Create a new checkout session
    const docRef = await addDoc(checkoutSessionRef, {
      price: PREMIUM_PRICE_ID,
      success_url: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${window.location.origin}/pricing`,
      metadata: {
        userId: auth.currentUser.uid,
      },
      automatic_tax: { enabled: true },
      tax_id_collection: { enabled: false },
      collect_shipping_address: false,
      allow_promotion_codes: true,
      mode: 'subscription'
    });

    // Wait for the CheckoutSession to get attached by the extension
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data() || {};
        if (error) {
          unsubscribe();
          reject(new Error(error.message));
        }
        if (url) {
          unsubscribe();
          window.location.assign(url);
          resolve(url);
        }
      });

      // Cleanup subscription after timeout
      setTimeout(() => {
        unsubscribe();
        reject(new Error("Checkout session creation timed out"));
      }, 10000);
    });
  } catch (error) {
    console.error("Checkout error:", error);
    throw error;
  }
}

// Helper function to check subscription status
export async function checkSubscriptionStatus(userId: string): Promise<SubscriptionStatus> {
  const db = getFirestore(app);
  try {
    const subscriptionsRef = collection(db, 'customers', userId, 'subscriptions');
    const snapshot = await getDocs(subscriptionsRef);
    
    if (!snapshot.empty) {
      const subscription = snapshot.docs[0].data();
      const status = subscription.status;
      return status === 'active' || status === 'trialing' ? 'active' : 'canceled';
    }
    
    return 'none';
  } catch (error) {
    console.error('Error checking subscription:', error);
    return 'none';
  }
}

// Helper function to check subscription status
export async function isUserPremium(): Promise<boolean> {
  const user = auth.currentUser;
  if (!user) return false;

  try {
    // Force refresh token to get latest claims
    await user.getIdToken(true);
    const decodedToken = await user.getIdTokenResult();
    
    if (decodedToken.claims.stripeRole === "premium") {
      return true;
    }

    // Fallback to checking Firestore subscription status
    const subscriptionsRef = collection(db, 'subscriptions', user.uid, 'subscriptions');
    const snapshot = await getDocs(subscriptionsRef);
    return snapshot.docs.some(doc => {
      const data = doc.data();
      return data.status === 'active' || data.status === 'trialing';
    });
  } catch (error) {
    console.error("Error checking premium status:", error);
    return false;
  }
}

// Maximum number of retry attempts for subscription listener
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds

// Add Firebase error interface at the top of the file
interface FirebaseError {
  code?: string;
  message: string;
}

// Enhanced subscription change listener with retries and better error handling
export function onSubscriptionChange(userId: string, callback: (isActive: boolean) => void): () => void {
  if (!userId) {
    console.error('No userId provided to onSubscriptionChange');
    return () => {};
  }

  let retryCount = 0;
  let unsubscribe: (() => void) | null = null;
  let isUnsubscribed = false;
  let retryTimeout: NodeJS.Timeout | null = null;

  // Helper function to handle subscription status check
  const checkSubscriptionStatus = async (snapshot: any) => {
    try {
      // First check if user is still signed in
      if (!auth.currentUser) {
        console.log('[Subscription] User no longer signed in, cleaning up listener');
        cleanup();
        return;
      }

      const hasActiveSubscription = snapshot.docs.some((doc: any) => {
        const data = doc.data();
        return data.status === 'active' || data.status === 'trialing';
      });

      // Only call callback if not unsubscribed and user is still signed in
      if (!isUnsubscribed && auth.currentUser) {
        callback(hasActiveSubscription);
      }
    } catch (error) {
      console.error('[Subscription] Error processing subscription status:', error);
      // Check if error is permission-related
      const firebaseError = error as FirebaseError;
      if (firebaseError.code === 'permission-denied' || !auth.currentUser) {
        console.log('[Subscription] Permission denied or user signed out, cleaning up');
        cleanup();
        return;
      }
      throw error; // Only propagate non-permission errors
    }
  };

  // Cleanup function
  const cleanup = () => {
    isUnsubscribed = true;
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }
    if (retryTimeout) {
      clearTimeout(retryTimeout);
      retryTimeout = null;
    }
    retryCount = 0; // Reset retry count
  };

  // Setup listener with retry logic
  const setupListener = () => {
    try {
      if (isUnsubscribed || !auth.currentUser) {
        console.log('[Subscription] Not setting up listener - unsubscribed or user signed out');
        return () => {};
      }

      const subscriptionsRef = collection(db, 'subscriptions', userId, 'subscriptions');
      
      unsubscribe = onSnapshot(subscriptionsRef, 
        async (snapshot) => {
          console.log('[Subscription] Change detected for user:', userId);
          try {
            await checkSubscriptionStatus(snapshot);
            retryCount = 0; // Reset retry count on successful execution
          } catch (error) {
            handleError(error);
          }
        },
        (error) => {
          console.error('[Subscription] Listener error:', error);
          // Check if error is permission-related
          if (error.code === 'permission-denied' || !auth.currentUser) {
            console.log('[Subscription] Permission denied or user signed out, cleaning up');
            cleanup();
            return;
          }
          handleError(error);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error('[Subscription] Setup error:', error);
      handleError(error);
      return () => {};
    }
  };

  // Error handler with retry logic
  const handleError = (error: unknown) => {
    if (isUnsubscribed || !auth.currentUser) {
      console.log('[Subscription] Not retrying - unsubscribed or user signed out');
      cleanup();
      return;
    }

    // Check if error is permission-related
    const firebaseError = error as FirebaseError;
    if (firebaseError.code === 'permission-denied') {
      console.log('[Subscription] Permission denied, cleaning up');
      cleanup();
      return;
    }

    console.error(`[Subscription] Error (attempt ${retryCount + 1}/${MAX_RETRIES}):`, error);

    if (retryCount < MAX_RETRIES) {
      retryCount++;
      console.log(`[Subscription] Retrying in ${RETRY_DELAY}ms...`);
      
      // Clean up existing listener before retry
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
      }

      // Clear any existing retry timeout
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }

      // Retry after delay
      retryTimeout = setTimeout(() => {
        if (!isUnsubscribed && auth.currentUser) {
          setupListener();
        } else {
          cleanup();
        }
      }, RETRY_DELAY);
    } else {
      console.error('[Subscription] Max retries reached or fatal error');
      cleanup();
      callback(false); // Assume no active subscription on fatal error
    }
  };

  // Initial setup
  setupListener();

  // Return cleanup function
  return cleanup;
}