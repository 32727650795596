import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import type { TemplateContent } from "../../../shared/types";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import DragDropBuilder from "./DragDropBuilder";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TemplateTierValues, type TemplateTier, type Template } from "@/types/shared";

interface EditTemplateProps {
  id: string;
  onSave: () => void;
  onCancel: () => void;
}

const ThumbnailSection = React.memo(({ 
  template, 
  onUpdate,
  refetch 
}: { 
  template: Template; 
  onUpdate: () => void;
  refetch: () => Promise<any>;
}) => {
  const { toast } = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [localThumbnail, setLocalThumbnail] = useState(template.thumbnail);

  // Update local thumbnail when template changes
  useEffect(() => {
    setLocalThumbnail(template.thumbnail);
  }, [template.thumbnail]);

  const handleThumbnailUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('thumbnail', file);

      const response = await fetch(`/api/templates/${template.id}/thumbnail`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to upload thumbnail');

      toast({ 
        title: "Thumbnail updated", 
        description: "The template thumbnail has been updated successfully."
      });
      onUpdate();
    } catch (error) {
      toast({ 
        title: "Upload failed", 
        description: "Failed to upload thumbnail. Please try again.",
        variant: "destructive" 
      });
    }
  };

  const handleRemoveThumbnail = async () => {
    if (!window.confirm('Are you sure you want to remove this thumbnail?')) return;

    try {
      setIsRemoving(true);

      // Use the dedicated thumbnail removal endpoint
      const response = await fetch(`/api/templates/${template.id}/thumbnail`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || 'Failed to remove thumbnail');
      }

      // Update local state
      setLocalThumbnail(null);

      toast({ 
        title: "Thumbnail removed", 
        description: "The template thumbnail has been removed successfully."
      });

      // Force a full template refetch to ensure all components are in sync
      await refetch();
      onUpdate();

    } catch (error: any) {
      console.error('Thumbnail removal error:', error);
      toast({ 
        title: "Failed to remove thumbnail", 
        description: error?.message || "Please try again later.",
        variant: "destructive" 
      });
      // Revert local state on error
      setLocalThumbnail(template.thumbnail);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div className="space-y-4 p-4 border border-white/10 rounded-lg bg-black/20">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">Template Thumbnail</h3>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => fileInputRef.current?.click()}
            disabled={isRemoving}
          >
            {localThumbnail ? 'Replace' : 'Add'} Thumbnail
          </Button>
          {localThumbnail && (
            <Button
              variant="destructive"
              onClick={handleRemoveThumbnail}
              disabled={isRemoving}
            >
              {isRemoving ? 'Removing...' : 'Remove'}
            </Button>
          )}
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) handleThumbnailUpload(file);
          // Clear the input value to allow uploading the same file again
          e.target.value = '';
        }}
      />

      {localThumbnail ? (
        <div className="relative aspect-video rounded-lg overflow-hidden">
          <img
            src={localThumbnail + '?t=' + new Date().getTime()}
            alt="Template thumbnail"
            className="absolute inset-0 w-full h-full object-cover"
          />
          {isRemoving && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <p className="text-white">Removing thumbnail...</p>
            </div>
          )}
        </div>
      ) : (
        <div className="aspect-video rounded-lg bg-gradient-to-br from-purple-900/30 via-black/50 to-cyan-900/30 flex items-center justify-center">
          <p className="text-white/60">No thumbnail set</p>
        </div>
      )}
    </div>
  );
});

ThumbnailSection.displayName = "ThumbnailSection";

export default function EditTemplate({ id, onSave, onCancel }: EditTemplateProps) {
  const { toast } = useToast();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [readme, setReadme] = useState("");
  const [content, setContent] = useState<any[]>([]);
  const [tier, setTier] = useState<TemplateTier>(TemplateTierValues.freemium);

  // Fetch template data
  const { data: template, refetch } = useQuery({
    queryKey: ["template", id],
    queryFn: async () => {
      const res = await fetch(`/api/templates/${id}`);
      if (!res.ok) throw new Error("Failed to fetch");
      return res.json();
    },
  });

  // Set initial values when template data is loaded
  useEffect(() => {
    if (template) {
      setTitle(template.title || "");
      setDescription(template.description || "");
      setReadme(template.readme || "");
      setContent(template.content || []);
      setTier(template.tier || TemplateTierValues.freemium);
    }
  }, [template]);

  const updateMutation = useMutation({
        mutationFn: async (data: {
          id: string;
          title: string;
          description: string;
          content: TemplateContent[];
          tier: TemplateTier;
          readme: string;
        }) => {
          if (!title.trim()) {
            throw new Error("Title is required");
          }

          // Format content items properly
          const formattedContent = content.map(item => {
            const formattedItem = { ...item };
            
            // Set basic fields
            formattedItem.id = formattedItem.id || String(Date.now());
            formattedItem.type = formattedItem.type || 'text';
            formattedItem.name = formattedItem.name || '';
            
            // Handle content based on type
            if (formattedItem.type === 'file') {
              // Keep file content as is
              formattedItem.content = item.content;
            } else if (typeof formattedItem.content === 'object') {
              // Stringify non-file object content
              formattedItem.content = JSON.stringify(formattedItem.content);
            }

            // Clean up undefined/null values
            Object.keys(formattedItem).forEach(key => {
              if (formattedItem[key] === undefined || formattedItem[key] === null) {
                delete formattedItem[key];
              }
            });

            return formattedItem;
          });

          // Prepare the update data
          const updateData = {
            title: title.trim(),
            description: description.trim() || null,
            readme: readme.trim() || null,
            content: formattedContent,
            tier: tier || TemplateTierValues.freemium,
            requiredTier: tier || TemplateTierValues.freemium,
            thumbnail: template?.thumbnail || null,
            updatedAt: new Date().toISOString()
          };

          try {
            // Ensure content is properly structured before sending
            const formattedUpdateData = {
              ...updateData,
              content: updateData.content.map(item => ({
                ...item,
                content: typeof item.content === 'object' ? JSON.stringify(item.content) : item.content,
                id: item.id || Date.now(),
                type: item.type || 'text'
              }))
            };

            const res = await fetch(`/api/templates/${id}`, {
              method: "PUT",
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify(formattedUpdateData),
            });

            if (!res.ok) {
              const errorData = await res.json().catch(() => null);
              console.error('Server response:', errorData);
              throw new Error(errorData?.error || "Failed to update template");
            }

            const updatedTemplate = await res.json();
            return updatedTemplate;
          } catch (error) {
            console.error('Update error:', error);
            throw error;
          }
        },
        onSuccess: (data) => {
          toast({ 
            title: "Template updated successfully",
            description: "All changes have been saved."
          });
          // Refresh the template data
          refetch();
          onSave();
        },
        onError: (error: Error) => {
          toast({ 
            title: "Failed to update template", 
            description: error.message || "Please try again",
            variant: "destructive" 
          });
        },
      });

  const handleSave = async () => {
    try {
      if (!title?.trim()) {
        toast({
          title: "Error",
          description: "Title is required",
          variant: "destructive"
        });
        return;
      }

      // Format and validate content
      const formattedContent = content
        .filter(item => item !== null && item !== undefined)
        .map(item => ({
          ...item,
          id: item.id || String(Date.now()),
          type: item.type || 'text',
          content: typeof item.content === 'object' ? JSON.stringify(item.content) : item.content
        }));

      // Track content changes
      const contentChanges = {
        added: formattedContent.filter(item => !template?.content?.find((t: any) => t.id === item.id)),
        removed: (template?.content || []).filter((item: any) => !formattedContent.find(c => c.id === item.id)),
        modified: formattedContent.filter(item => {
          const original = template?.content?.find((t: any) => t.id === item.id);
          return original && JSON.stringify(original) !== JSON.stringify(item);
        })
      };

      // Log changes for tracking
      console.log('Template content changes:', {
        templateId: id,
        changes: contentChanges,
        timestamp: new Date().toISOString()
      });

      // Prepare update data
      const updateData = {
        id,
        title: title.trim(),
        description: description?.trim() || '',
        content: formattedContent,
        tier,
        readme: readme?.trim() || '',
      };

      // Force immediate preview update
      setContent(formattedContent);

      await updateMutation.mutateAsync(updateData);

      toast({
        title: "Success", 
        description: "Template updated successfully"
      });

      // Force immediate refetch to sync UI with server
      const result = await refetch();
      if (result.data) {
        setContent(result.data.content || []);
      }
      onSave();
    } catch (error) {
      console.error('Save error:', error);
      toast({
        title: "Error",
        description: "Failed to save template",
        variant: "destructive"
      });
    }
  };

  // Update the refetch logic to ensure it's properly awaited
  const handleRefetch = async () => {
    try {
      const result = await refetch();
      if (result.data) {
        // Update local state with new data
        setTitle(result.data.title || "");
        setDescription(result.data.description || "");
        setReadme(result.data.readme || "");
        setContent(result.data.content || []);
        setTier(result.data.tier || TemplateTierValues.freemium);
      }
    } catch (error) {
      console.error('Refetch error:', error);
      toast({ 
        title: "Failed to refresh template data", 
        description: "Please try again",
        variant: "destructive" 
      });
    }
  };

  return (
    <div className="container max-w-4xl mx-auto p-6 space-y-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold">Edit Template</h1>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={onCancel}
            disabled={updateMutation.isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={updateMutation.isPending}
          >
            {updateMutation.isPending ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </div>

      {template && (
        <ThumbnailSection 
          template={template} 
          onUpdate={handleRefetch}
          refetch={refetch}
        />
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-6">
          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6 space-y-4">
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">
                  Basic Information
                </h2>
                <div className="space-y-4">
                  <Input
                    placeholder="Template Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="text-lg bg-black/30 border-white/10 focus:border-primary/50"
                  />
                  <Textarea
                    placeholder="Template Description - Brief overview of what this template does"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="h-24 bg-black/30 border-white/10 focus:border-primary/50"
                  />
                  <div className="space-y-2">
                    <label className="block text-sm font-medium">Template Tier</label>
                    <Select
                      value={tier}
                      onValueChange={(value) => setTier(value as TemplateTier)}
                    >
                      <SelectTrigger className="bg-black/30 border-white/10">
                        <SelectValue placeholder="Select tier" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(TemplateTierValues).map(([key, value]) => (
                          <SelectItem key={key} value={value}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="text-2xl font-semibold bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Documentation</h2>
                <Textarea
                  placeholder="README - Add detailed instructions, requirements, use cases, and any other relevant information (Markdown supported)"
                  value={readme}
                  onChange={(e) => setReadme(e.target.value)}
                  className="h-48 font-mono text-sm bg-black/30 border-white/10 focus:border-primary/50"
                />
              </div>
            </CardContent>
          </Card>

          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Template Builder</h2>
              <DragDropBuilder 
                initialContent={content}
                onChange={setContent}
              />
            </CardContent>
          </Card>
        </div>

        <div className="space-y-6">
          <Card className="bg-black/30 backdrop-blur-sm border-white/10">
            <CardContent className="p-6">
              <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white via-primary to-purple-400 bg-clip-text text-transparent">Content Preview</h2>
              <div className="prose prose-invert max-w-none">
                {readme && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">README Preview</h3>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {readme}
                    </ReactMarkdown>
                  </div>
                )}
                {content.length > 0 && (
                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-semibold">Content Preview</h3>
                      <Button 
                        variant="destructive" 
                        size="sm"
                        onClick={() => setContent([])}
                      >
                        Clear All
                      </Button>
                    </div>
                    <DragDropBuilder
                      initialContent={content}
                      onChange={setContent}
                      readOnly={false}
                      onRemove={(id) => {
                        setContent(prev => prev.filter(item => item.id !== id));
                      }}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}