import { FirebaseError } from 'firebase/app';
import { auth } from "./firebase";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { useState, useEffect, useRef } from "react";
import { onSubscriptionChange, checkSubscriptionStatus } from "./stripe";
import { 
  UserTier, 
  UserTierValues, 
  TemplateTier,
  SubscriptionStatus,
  isSubscriptionActive,
  formatSubscriptionStatus
} from "@/types/shared";
import { ADMIN_EMAIL } from '@/types/auth';
import type { AuthUser, UserRole } from "@/types/auth";

// Token management
let tokenCache: { token: string | null; expiry: number } = { token: null, expiry: 0 };
let lastTokenRefresh = 0;
const REFRESH_COOLDOWN = 5000; // 5 seconds
const TOKEN_CACHE_DURATION = 3600000; // 1 hour

let cachedToken: string | null = null;
let tokenExpiry: number | null = null;

// Instead, let's use a regular object to track sent webhooks
const webhookSentTracker: {[key: string]: boolean} = {};

async function getAuthTokenWithCache(user: FirebaseUser, forceRefresh = false): Promise<string | null> {
  const now = Date.now();
  
  // Return cached token if valid and not forcing refresh
  if (!forceRefresh && tokenCache.token && now < tokenCache.expiry) {
    return tokenCache.token;
  }

  // Check refresh cooldown
  if (now - lastTokenRefresh < REFRESH_COOLDOWN) {
    return tokenCache.token;
  }

  try {
    lastTokenRefresh = now;
    const token = await user.getIdToken(forceRefresh);
    tokenCache = {
      token,
      expiry: now + TOKEN_CACHE_DURATION
    };
    return token;
  } catch (error) {
    if (error instanceof FirebaseError && error.code === 'auth/quota-exceeded') {
      console.warn('Token refresh quota exceeded, using cached token');
      return tokenCache.token;
    }
    throw error;
  }
}

export function useAuth() {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const unsubscribeRef = useRef<(() => void) | null>(null);
  const subscriptionCleanupRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    // Auth state change listener
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        // Clean up any existing subscription listener
        if (subscriptionCleanupRef.current) {
          subscriptionCleanupRef.current();
          subscriptionCleanupRef.current = null;
        }

        if (!firebaseUser) {
          setUser(null);
          setLoading(false);
          return;
        }

        // Get initial user role
        const { isAdmin, tier, subscriptionStatus } = await getUserRole(firebaseUser);
        
        // Set initial user state
        const authUser: AuthUser = {
          ...firebaseUser,
          isAdmin,
          tier,
          subscriptionStatus
        };
        setUser(authUser);

        // Check if this is a new user (metadata.creationTime === lastSignInTime)
        if (firebaseUser.metadata.creationTime === firebaseUser.metadata.lastSignInTime) {
          // Check if we've already sent a webhook for this user
          if (webhookSentTracker[firebaseUser.uid]) {
            console.log('[Auth] Webhook already sent for user:', firebaseUser.email);
            return;
          }

          console.log('[Auth] New user detected, sending webhook...');

          try {
            const response = await fetch('/api/webhook/lead', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: firebaseUser.email,
                uid: firebaseUser.uid,
                createdAt: firebaseUser.metadata.creationTime,
                tier,
                isAdmin,
                source: window.location.hostname,
                subscriptionStatus,
                metadata: {
                  provider: firebaseUser.providerData[0]?.providerId || 'unknown',
                }
              })
            });

            if (!response.ok) {
              throw new Error(`Webhook failed with status: ${response.status}`);
            }

            console.log('[Auth] Webhook sent successfully for new user:', firebaseUser.email);
            webhookSentTracker[firebaseUser.uid] = true;
          } catch (error) {
            console.error('[Auth] Error sending lead webhook:', error);
          }
        }

        // Set up subscription listener only if user is authenticated
        subscriptionCleanupRef.current = onSubscriptionChange(firebaseUser.uid, async (isActive) => {
          // Only update if the subscription status has changed
          const newStatus = isActive ? 'active' : 'none';
          if (newStatus !== authUser.subscriptionStatus) {
            // Get updated role with new subscription status
            const updatedRole = await getUserRole(firebaseUser);
            setUser(prev => prev ? {
              ...prev,
              ...updatedRole
            } : null);
          }
        });

      } catch (error) {
        console.error('Error in auth state change:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    });

    // Cleanup function
    return () => {
      unsubscribe();
      if (subscriptionCleanupRef.current) {
        subscriptionCleanupRef.current();
        subscriptionCleanupRef.current = null;
      }
    };
  }, []); // Empty dependency array since we want this to run only once

  return { user, loading };
}

export { isSubscriptionActive, formatSubscriptionStatus };
export type { SubscriptionStatus };

export async function getUserRole(user: FirebaseUser | null): Promise<UserRole & { subscriptionStatus: SubscriptionStatus }> {
  if (!user) {
    return { isAdmin: false, tier: UserTierValues.visitor, subscriptionStatus: 'none' };
  }

  // Force refresh token to get latest claims
  await user.getIdToken(true);
  const decodedToken = await user.getIdTokenResult();
  
  if (user.email === ADMIN_EMAIL) {
    return { isAdmin: true, tier: UserTierValues.ultimate, subscriptionStatus: 'active' };
  }

  // Check current subscription status
  const subscriptionStatus: SubscriptionStatus = await checkSubscriptionStatus(user.uid);
  const hasActiveSubscription = isSubscriptionActive(subscriptionStatus);
  
  if (decodedToken.claims.stripeRole === 'premium' && hasActiveSubscription) {
    return { 
      isAdmin: false, 
      tier: UserTierValues.premium,
      subscriptionStatus 
    };
  }

  // Default to freemium for authenticated users
  return { 
    isAdmin: false, 
    tier: UserTierValues.freemium,
    subscriptionStatus
  };
}

export function canAccessTemplate(userTier: UserTier, templateTier: TemplateTier, subscriptionStatus: SubscriptionStatus): boolean {
  console.log('Access Check:', { userTier, templateTier, subscriptionStatus });
  
  // Ultimate tier always has full access
  if (userTier === UserTierValues.ultimate) {
    return true;
  }
  
  // Premium users with active subscription get access to all content except ultimate
  if (userTier === UserTierValues.premium) {
    // Check for active subscription using the shared helper
    if (isSubscriptionActive(subscriptionStatus)) {
      return templateTier !== UserTierValues.ultimate;
    }
    // Premium users without active subscription get freemium access
    return templateTier === UserTierValues.freemium;
  }
  
  // Freemium users only get access to freemium content
  if (userTier === UserTierValues.freemium) {
    return templateTier === UserTierValues.freemium;
  }

  // Visitors get no access
  return false;
}

export async function getAuthToken() {
  // Return cached token if it's still valid (with 5 min buffer)
  if (cachedToken && tokenExpiry && Date.now() < tokenExpiry - 300000) {
    return cachedToken;
  }

  const user = auth.currentUser;
  if (!user) {
    throw new Error('No user logged in');
  }

  try {
    const token = await user.getIdToken();
    cachedToken = token;
    tokenExpiry = Date.now() + 3600000; // 1 hour
    return token;
  } catch (error) {
    console.error('Error getting auth token:', error);
    throw error;
  }
}
