import { z } from 'zod';

// Define tier values and types
export type TierValue = 'visitor' | 'freemium' | 'premium' | 'ultimate';
export type SubscriptionStatus = 'active' | 'trialing' | 'canceled' | 'none';

// Common tier values
export const TierValues = {
  visitor: 'visitor' as const,
  freemium: 'freemium' as const,
  premium: 'premium' as const,
  ultimate: 'ultimate' as const
} as const;

// User and Template tier values
export const UserTierValues = {
  visitor: TierValues.visitor,
  freemium: TierValues.freemium,
  premium: TierValues.premium,
  ultimate: TierValues.ultimate
} as const;

export const TemplateTierValues = {
  freemium: TierValues.freemium,
  premium: TierValues.premium,
  ultimate: TierValues.ultimate
} as const;

export type UserTier = typeof UserTierValues[keyof typeof UserTierValues];
export type TemplateTier = 'freemium' | 'premium' | 'ultimate';

// Helper functions
export const tierLevels = {
  [UserTierValues.visitor]: 0,
  [UserTierValues.freemium]: 1,
  [UserTierValues.premium]: 2,
  [UserTierValues.ultimate]: 3
} as const;

export const tierFeatures = {
  [UserTierValues.visitor]: {
    label: 'Visitor',
    color: 'gray',
    features: ['view']
  },
  [UserTierValues.freemium]: {
    label: 'Free',
    color: 'blue',
    features: ['view', 'copy', 'share']
  },
  [UserTierValues.premium]: {
    label: 'Premium',
    color: 'purple',
    features: ['view', 'copy', 'share', 'download']
  },
  [UserTierValues.ultimate]: {
    label: 'Ultimate',
    color: 'gold',
    features: ['view', 'copy', 'share', 'download', 'edit']
  }
} as const;

// Tier checking logic
function canAccessTier(userTier: UserTier, requiredTier: TemplateTier, subscriptionStatus: SubscriptionStatus = 'none'): boolean {
  // Ultimate tier always has full access
  if (userTier === UserTierValues.ultimate) {
    return true;
  }

  // Premium users with active subscription get access to all content except ultimate
  if (userTier === UserTierValues.premium) {
    if (isSubscriptionActive(subscriptionStatus)) {
      return requiredTier !== UserTierValues.ultimate;
    }
    // Premium users without active subscription get freemium access
    return requiredTier === UserTierValues.freemium;
  }

  // Freemium users only get access to freemium content
  if (userTier === UserTierValues.freemium) {
    return requiredTier === UserTierValues.freemium;
  }

  // Visitors get no access
  return false;
}

// Subscription status check
function isSubscriptionActive(status: SubscriptionStatus): boolean {
  return status === 'active' || status === 'trialing';
}

// Subscription status formatting
function formatSubscriptionStatus(status: SubscriptionStatus): string {
  switch (status) {
    case 'active':
      return 'Active';
    case 'trialing':
      return 'Trial';
    case 'canceled':
      return 'Canceled';
    case 'none':
      return 'None';
    default:
      return 'Unknown';
  }
}

// Feature access check
function canPerformAction(
  action: 'view' | 'copy' | 'download' | 'share' | 'edit' | 'delete' | 'create',
  userTier: UserTier,
  isAdmin: boolean,
  templateTier?: TemplateTier,
  subscriptionStatus: SubscriptionStatus = 'none'
): boolean {
  // Admin-only actions
  if (['edit', 'delete', 'create'].includes(action)) {
    return isAdmin && userTier === UserTierValues.ultimate;
  }

  // If no tier specified or freemium template, allow access for logged-in users
  if (!templateTier || templateTier === UserTierValues.freemium) {
    return userTier !== UserTierValues.visitor;
  }

  // Check if user can access the template tier
  if (!canAccessTier(userTier, templateTier, subscriptionStatus)) {
    return false;
  }

  // Action-specific checks
  switch (action) {
    case 'view':
      return true; // If they can access the tier, they can view
    case 'copy':
    case 'download':
    case 'share':
      return userTier !== UserTierValues.visitor;
    default:
      return false;
  }
}

// Helper function to get features by tier
function getFeaturesByTier(tier: UserTier): string[] {
  // Create a new mutable array from the readonly array
  const features = tierFeatures[tier]?.features || tierFeatures[UserTierValues.visitor].features;
  return [...features];
}

// Export all tier-related functions
export {
  canAccessTier,
  isSubscriptionActive,
  formatSubscriptionStatus,
  canPerformAction,
  getFeaturesByTier
};

// Template content schema and types
export const templateContentSchema = z.object({
  id: z.union([z.string(), z.number()]),
  type: z.enum(['text', 'code', 'link', 'file', 'application/json']),
  content: z.string(),
  name: z.string().optional(),
  fileUrl: z.string().optional(),
  filePath: z.string().optional(),
  fileType: z.string().optional(),
  fileSize: z.number().optional(),
  title: z.string().optional(),
  language: z.string().optional()
});

export type TemplateContent = {
  id: string | number;
  type: 'text' | 'code' | 'file' | 'link';
  content: string;
  name?: string;
  title?: string;
  fileUrl?: string;
  filePath?: string;
  fileType?: string;
  fileSize?: number;
  language?: string;
};

export interface BaseTemplate {
  title: string;
  description: string | null;
  content: TemplateContent[];
  readme?: string;
  tier: TemplateTier;
  features?: string[];
  implementationGuide?: Array<string | { step?: string; instruction?: string }>;
  exampleOutputs?: Record<string, {
    type: 'text' | 'code';
    content: string;
    language?: string;
  }>;
}

export interface Template {
  id: number;
  title: string;
  description: string | null;
  thumbnail: string | null;
  tier: TemplateTier;
  requiredTier: TemplateTier;
  content: TemplateContent[];
  isLocked?: boolean;
  views: number;
  shares: number;
  createdAt: Date;
  updatedAt: Date;
  readme?: string | null;
  features?: any[];
  createdBy?: string;
  categoryId?: number | null;
  implementationGuide?: Array<string | { step?: string; instruction?: string }>;
  exampleOutputs?: Record<string, {
    type: 'text' | 'code';
    content: string;
    language?: string;
  }>;
}

export interface GeneratedTemplate extends BaseTemplate {
  id?: number;
}

export interface ParsedTemplate extends Template {}

export interface StreamResponse {
  type: 'raw' | 'preview';
  content: string | {
    title: string;
    description: string;
    content: TemplateContent[];
    readme?: string;
    tier: TemplateTier;
    features?: string[];
    implementationGuide?: string[];
    exampleOutputs?: Record<string, {
      type: 'text' | 'code';
      content: string;
      language?: string;
    }>;
  };
}

export interface StreamedTemplate extends BaseTemplate {
  id: number;
  requiredTier: TemplateTier;
  isLocked: boolean;
  views: number;
  shares: number;
  categoryId: number | null;
  thumbnail: string | null;
  createdAt: Date;
  updatedAt: Date;
}

// User session and role interfaces
export interface UserSession {
  uid: string;
  email: string | null;
  isAdmin: boolean;
  tier: UserTier;
  requiredTier?: TemplateTier;
}

export interface UserRole {
  isAdmin: boolean;
  tier: UserTier;
}

// Global type augmentations
declare global {
  namespace Express {
    interface User {
      email: string | null;
      uid: string;
      isAdmin: boolean;
      tier: UserTier;
    }
  }
}
