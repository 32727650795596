import { useParams, useLocation } from "wouter";
import ViewTemplate from "@/components/ViewTemplate";
import EditTemplate from "@/components/EditTemplate";
import CreateTemplate from "@/components/CreateTemplate";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@/lib/firebase";
import { getUserRole } from "@/types/auth";
import { UserTierValues } from "@/types/shared";
import { useToast } from "@/hooks/use-toast";

export default function Builder() {
  const { id } = useParams();
  const [location, navigate] = useLocation();
  const [user] = useAuthState(auth);
  const userRole = getUserRole(user?.email || null);
  const { toast } = useToast();

  const checkUltimateAccess = () => {
    if (!userRole.isAdmin || userRole.tier !== UserTierValues.ultimate) {
      toast({
        title: "Access Denied",
        description: "Only ultimate tier admins can create or edit templates",
        variant: "destructive"
      });
      navigate("/");
      return false;
    }
    return true;
  };

  // Create new template
  if (id === "new") {
    if (!checkUltimateAccess()) return null;
    return (
      <CreateTemplate
        onCreated={(newId) => navigate(`/builder/${newId}`)}
        onCancel={() => navigate("/")}
      />
    );
  }

  if (!id) {
    navigate("/");
    return null;
  }

  // Check if we're on the edit route
  const isEdit = location.endsWith("/edit");

  if (isEdit) {
    if (!checkUltimateAccess()) return null;
    return (
      <EditTemplate
        id={id}
        onSave={() => navigate(`/builder/${id}`)}
        onCancel={() => navigate(`/builder/${id}`)}
      />
    );
  }

  // Default to view template
  return (
    <ViewTemplate
      id={id}
      onEdit={() => navigate(`/builder/${id}/edit`)}
      onBack={() => navigate("/")}
    />
  );
}